<script setup lang="ts">
import { leaseSectorOptions } from '~/constants/LeaseSector'
import formkitTheme from '@ui/formkit/formkit.theme'
import type { FormKitNode } from '@formkit/core'
import type { ILeaseCardCreateLeaseBody } from '@manager'

const props = defineProps<{
  divisionId: string
}>()

const emit = defineEmits<{
  close: []
}>()

const router = useRouter()
const { mutateAsync: createLease } = useApiLeaseCardCreateLeaseMutation(
  props.divisionId,
)
const form = ref<{ node: FormKitNode }>()
const submitHandler = useSubmitHandler<ILeaseCardCreateLeaseBody>(
  (data) => createLease(data),
  {
    loadingMessage: 'Creating lease...',
    successMessage: 'Lease created successfully',
    errorMessage: 'Failed to create lease',
    onSuccess: (newLeaseId) => {
      emit('close')
      nextTick(() => {
        router.push(`/division/${props.divisionId}/lease/${newLeaseId}/manager`)
      })
    },
  },
)
</script>

<template>
  <ModalBase
    title="Create a new lease"
    primary-button-text="Create"
    modal-class="max-w-[600px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    @confirm="form?.node.submit()"
    @close="emit('close')"
  >
    <FormKit
      ref="form"
      type="form"
      form-class="space-y-4 w-full"
      :actions="false"
      @submit="submitHandler"
    >
      <FormKit type="text" name="name" label="Name" validation="required" />

      <FormKit
        type="listbox"
        name="sector"
        label="Sector"
        :options="leaseSectorOptions"
        validation="required"
      >
        <template #selection="{ value, displayValue }">
          <div class="flex items-center">
            <NuxtIcon
              v-if="value?.icon"
              :name="value.icon"
              filled
              class="mr-2 mt-0.5 text-lg"
            />

            <span class="text-sm font-semibold text-gray-200">
              {{ displayValue }}
            </span>
          </div>
        </template>

        <template #listitem="{ option, label }">
          <div class="flex items-center">
            <NuxtIcon :name="option.icon" filled class="mr-2 text-lg" />

            <span :class="formkitTheme.listbox.listitemInner">
              {{ label }}
            </span>
          </div>
        </template>
      </FormKit>
    </FormKit>
  </ModalBase>
</template>
