import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { Sector } from '@register'

export interface ILeaseCardCreateLeaseBody {
  name: string
  sector: Sector
}

export const useApiLeaseCardCreateLeaseMutation = (divisionId: string) => {
  const leaseId = '00000000-0000-0000-0000-000000000000'
  const fieldGroupId = 0
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: ILeaseCardCreateLeaseBody) =>
      fetchAPI<string>(
        `/companies/${divisionId}/leases/${leaseId}/storage/node/${fieldGroupId}`,
        {
          method: 'POST',
          body,
        },
      ),
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: ['useApiPortfolio', divisionId],
      })
    },
  })
}
